import AchouLevouHeader from "./AchouLevouHeader";
import AchouLevouFooter from "./AchouLevouFooter";
import AchouLevouRules from "./AchouLevouRules";
import VoucherCode from "../../../../components/VoucherCode";
import parabensImage from "../../../../assets/images/parabens-image.png";
import "./AchouLevouContainer.scss";
import "./AchouLevouVoucherCode.scss";

const AchouLevouContainer = ({ product }) => {
  return (
    <>
      <section className="redemption-code-container">
        <AchouLevouHeader />
        <div className="parabens-header">
          <img src={parabensImage} alt="Parabéns" />
        </div>
        <div className="redemption-code-content row justify-content-center">
          <div className="card redemption-code-card ">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4">
                  <div className="voucher-image-redemption-code">
                    <img
                      src={product.image?.medium || product.image?.original}
                      alt={product.name}
                    />
                  </div>
                </div>
                <div className="col-sm-8 align-self-center">
                  <p className="voucher-name">{product.name}</p>
                </div>
              </div>
              <VoucherCode
                code={product.codes.voucherCode}
                pin={product.codes.voucherPinCode}
                merchantId={product.merchantId}
                link={product.codes.link}
                showCodeMessage="Ver meu código"
              />
            </div>
            <div className="card-footer">
              Válido para utilização exclusiva no App.
            </div>
          </div>
        </div>
        <AchouLevouRules rules={product.features} />
        <AchouLevouFooter />
      </section>
    </>
  );
};

export default AchouLevouContainer;
