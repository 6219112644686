import React from "react";
import achoulevou from "../../../../assets/images/achoulevou_logo.png";
import "./AchouLevouHeader.scss";

const AchouLevouHeader = () => {
  return (
    <div className="redemption-code-header">
        <img src={achoulevou} alt="Parabens" />
    </div>
  );
};

export default AchouLevouHeader;
