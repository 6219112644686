import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import yn from "yn";
import API, { ENDPOINTS } from "../../services";
import MkpSkeletonContainer from "./components/mkp/MkpSkeletonContainer";
import "./RedemptionPage.scss";
import Customers from "../../utils/consts";
import MkpContainer from "./components/mkp/MkpContainer";
import VibeContainer from "./components/vibe/VibeContainer";
import FamilhaoContainer from "./components/familhao/FamilhaoContainer";
import LigaContainer from "./components/liga/LigaContainer";
import AchouLevouContainer from "./components/achoulevou/AchouLevouContainer";
import SkeletonContainer from "./components/SkeletonContainer";


function setSupplierContainer(s, product) {
  switch (s) {
    case Customers.FAMILHAO: return <FamilhaoContainer {...{product}} />
    case Customers.VIBE: return <VibeContainer {...{product}}/>
    case Customers.LIGA: return <LigaContainer {... {product}}/>
    case Customers.ACHOULEVOU: return <AchouLevouContainer {...{product}} />
    default: return <MkpContainer {...{product}} />
  }
}
function setSkeletonPage(s) {
  switch (s) {
    case Customers.MKP: return <MkpSkeletonContainer />
    default: return <SkeletonContainer />
  }
}

export const RedemptionPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const recaptchaToken = searchParams.get("recaptcha-token");
  const h = searchParams.get("h");
  const c = searchParams.get("c");
  const s = searchParams.get("s") ?? 1;

  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState({});

  const goToVerifyYourIdentityPage = useCallback(() => {
    navigate(`/verify-your-identity?h=${h}&c=${c}&s=${s}`);
  }, [navigate, h, c, s]);

  const handleError = useCallback(
    (err) => {
      console.error(err);

      const [errorDescription] = err?.response?.data?.errors || [];
      if (errorDescription === "Recaptcha inválido.")
        goToVerifyYourIdentityPage();
      else navigate("/404");
    },
    [navigate, goToVerifyYourIdentityPage]
  );

  const fetch = useCallback(
    (h, c, recaptchaToken) => {
      setIsLoading(true);

      API(ENDPOINTS.revealVoucher(h, c, recaptchaToken))
        .then(({ data }) => setProduct(data.data))
        .catch(handleError)
        .finally((_) => setIsLoading(false));
    },
    [handleError]
  );

  useEffect(() => {
    if (yn(process.env.REACT_APP_SERVICE_USE_RECAPTCHA) && !recaptchaToken)
      goToVerifyYourIdentityPage();
    else fetch(h, c, recaptchaToken);
  }, [fetch, h, c, goToVerifyYourIdentityPage, recaptchaToken]);

  return (
    <section className="redemption-page">
      {isLoading ? (
        <SkeletonContainer />
      ) :
            setSupplierContainer(s, product)
      }
    </section>
  );
};
