import React from "react";
import "./AchouLevouRules.scss";

const AchouLevouRules = ({ rules }) => {
  if (!rules || Object.keys(rules).length === 0) {
    return null;
  }

  return (
    <div className="rules-section">
      <h2>Confira as regras:</h2>
      <div className="rules-content">
        <ul>
          {Object.entries(rules).map(([title, content], index) => (
            <li key={index}>
              <strong>{title}</strong>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};


export default AchouLevouRules;
