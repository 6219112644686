import { useState } from "react";
import ContentCopy from "./ContentCopy";

const VoucherCode = ({ code, pin, merchantId, link, showCodeMessage }) => {
  const [showCode, setShowCode] = useState(false);

  const handleRevealCodeClick = () => setShowCode(true);

  return (
    <>
      <div className="row justify-content-center">
        {!showCode ? (
          <a
            href="#reveal-code"
            className="btn btn-primary btn-lg redemption-code-button"
            onClick={handleRevealCodeClick}
          >
            <span>{showCodeMessage}</span>
          </a>
        ) : link ? (
          <span className="btn btn-primary btn-lg redemption-code-button">
            <span style={{ ...calcFontSize(code.length) }}>
              <a href={link} target={"_blank"} >{code}</a>
            </span>
          </span>
        ) : (
          <span className="btn btn-primary btn-lg redemption-code-button">
            <span style={{ ...calcFontSize(code.length) }}>
              <ContentCopy>{code}</ContentCopy>
            </span>
          </span>
        )}
      </div>
      <div>
        {pin ? (
          <>
            <p className="text-center redemption-pin-code mt-5 mb-3">
              SENHA DE LIBERAÇÃO
              <hr className="mx-4" />
              <span className="redemption-pin-code-value mb-4">
                {showCode ? <ContentCopy>{pin}</ContentCopy> : "*******"}
              </span>
              <br />
              <span className="redemption-pin-code-legend">
                Apresente essa senha na hora da compra
              </span>
            </p>
          </>
        ) : (
          <>
            {merchantId && showCode &&
              <a href={"https://api.shophub.com.br/shortener/voucher/" + merchantId} target="_blanck">
                <button className="voucher-code-button" >IR PARA A LOJA</button>
              </a>
            }
          </>
        )}
      </div>
    </>
  );
};

const calcFontSize = (length) => {
  if (length <= 20) return { fontSize: "20px" };

  return { fontSize: `calc(min(90vw, 550px) / ${length})` };
};

export default VoucherCode;
